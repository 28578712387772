.App {
  text-align: center;
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scrollButton {
  position: fixed;
  right: 5%;
  bottom: 40px;
  height: 20px;
  font-size: 4rem;
  z-index: 1;
  cursor: pointer;
  color: 3b3f8f;
}

.shadeBg {
  overflow: hidden;
  background-color: #f5f5f5;
}

.breathingSpace {
  padding-left: 10%;
  padding-right: 10%;
}
@media (max-width: 786px) {
  .breathingSpace {
    padding-left: 2%;
    padding-right: 2%;
  }
}

.whiteButton {
  display: inline-block;
  font-size: 1em;
  background: #efefef;
  padding: 10px 30px;
  text-decoration: none;
  font-weight: 500;
  margin-top: 10px;
  color: #3b3f8f;
  letter-spacing: 2px;
  transition: 0.2s;
}
.whiteButton:hover {
  letter-spacing: 6px;
  color: #3988b5;
  border-bottom: 2px solid #3b3f8f;
}